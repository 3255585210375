import organizationsConfig from "../../src/organizations.conf.json";
const { id: organizationId, name: organizationName } = organizationsConfig[
  process.env.REACT_APP_NAME
];

export default {
  port: process.env.PORT || 3000,
  gatewayMerchantId:
    process.env.GATEWAY_MERCHANT_ID ||
    "pk_test_0d90b88a-3e16-44aa-bdbf-cca1c1757cae",
  apiBase: process.env.API_HOST || "https://api.dev.ulagent.com",
  checkoutApiUrl: "https://api.sandbox.checkout.com",
  organizationId,
  organizationName,
  name: process.env.REACT_APP_ENV,
  payosuKey: "ed7c5e17-cb49-4f69-b62b-050c8adf19b1",
  payosuScript: "//widget.sandbox.payosu.com/index.js",
  country: "DE",
  password:"W4rOzoYq8F^WzjM8gLXyo^wrhqosT!ko",
  secret:"EN93efE3nWXYMZ2JEuteqOfN6wqQKBT5"
};
